import { gql } from '@apollo/client';
import { DonateFixedFragment, DonateMultipleFragment } from '@components/Donate/Donate.fragment';
import { VoucherFixedFragment, VoucherMultipleFragment } from '../Voucher/Voucher.fragment';

export const ShortHeroFragment = gql`
  fragment ShortHeroParagraph on ShortHeroParagraph {
    title
    eyebrow
    showAsCircle
    backgroundImage {
      url
      sm
      md
      lg
      xl
    }
    primaryCta {
      title
      url
      target
    }
    secondaryCta {
      title
      url
      target
    }
  }
`;

export const VideoHeroFragment = gql`
  fragment VideoHeroParagraph on VideoHeroParagraph {
    title
    eyebrow
    showAsCircle
    backgroundVideo {
      id
      url
      thumbnail
    }
    primaryCta {
      title
      url
      target
    }
    secondaryCta {
      title
      url
      target
    }
    colourTheme
  }
`;

export const TallHeroFragment = gql`
  fragment TallHeroParagraph on TallHeroParagraph {
    slides {
      title
      eyebrow
      backgroundImage {
        url
        sm
        md
        lg
        xl
      }
      primaryCta {
        title
        url
        target
      }
      secondaryCta {
        title
        url
        target
      }
    }
  }
`;

export const CampaignHeroFragment = gql`
  fragment CampaignHeroParagraph on CampaignHeroParagraph {
    title
    backgroundImage {
      url
      sm
      md
      lg
      xl
    }
    primaryCta {
      title
      url
      target
    }
  }
`;

export const DonateHeroFragment = gql`
  ${DonateFixedFragment}
  ${DonateMultipleFragment}
  fragment DonateHeroParagraph on DonateHeroParagraph {
    title
    backgroundImage {
      url
      sm
      md
      lg
      xl
    }
    content {
      ...DonateFixedAmountParagraph
      ...DonateMultipleAmountsParagraph
    }
  }
`;

export const VoucherHeroFragment = gql`
  ${VoucherFixedFragment}
  ${VoucherMultipleFragment}
  fragment VoucherHeroParagraph on VoucherHeroParagraph {
    title
    backgroundImage {
      url
      sm
      md
      lg
      xl
    }
    content {
      ...VoucherFixedAmountParagraph
      ...VoucherMultipleAmountsParagraph
    }
  }
`;
